import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CallbackResolver implements Resolve<SSOCallback> {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot) {
    let custom_domain = route.queryParams.custom_domain;

    if (custom_domain === 'None') {
      custom_domain = undefined;
    }

    const payload: SSOCallback = {
      token: route.queryParams.token,
      app: route.queryParams.app,
      custom_domain
    };

    return payload;
  }

}

export interface SSOCallback {
  token: string;
  app: string;
  custom_domain?: string;
}
