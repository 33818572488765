import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackResolver } from './resolvers/callback.resolver';
import { AppComponent } from './components/app/app.component';

const routes: Routes = [
  {
    path: 'sso-complete',
    component: AppComponent,
    resolve: { callback: CallbackResolver  }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: [
  ],
  providers: [
    CallbackResolver
  ]
})
export class AppRoutingModule {}
