import { Component, OnInit, Input } from '@angular/core';
import { SSOCallback } from '../../resolvers/callback.resolver';
import { ActivatedRoute } from '@angular/router';
import { delay } from 'q';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) {

    this.route.data.subscribe(data => {
      this.callback = data.callback;
    });
  }

  callback: SSOCallback;

  async ngOnInit() {
    window.addEventListener('message', (event) => this.onRecieveMessage(event), false);

    if (!this.callback) {

    } else {
      this.postPayloadMessage();

      await this.redirect();
    }
  }

  postPayloadMessage() {
    const postPayload: WindowMessage = {
      name: 'SSO_SUCCESS',
      data: this.callback
    };

    window.parent.postMessage(JSON.stringify(postPayload), '*');
  }

  onRecieveMessage(event: MessageEvent) {
    if (event.data === 'close') {
      window.close();
    }
  }

  redirect() {
    return delay(1000)
      .then(() => {
        const domain = this.callback.custom_domain || environment.domain || window.location.hostname;

        const href = `https://${domain}/${this.callback.app}?sso_token=${this.callback.token}`;

        location.href = href;
      });
  }
}

interface WindowMessage {
  name: string;
  data: object;
}
